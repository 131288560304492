
import { defineComponent, computed } from 'vue'
import BookingInfo from '@/components/BookingInfo.vue'
import LocationInfo from '@/components/LocationInfo.vue'
import AssignLab from '@/components/AssignLab.vue'
import AssignTesters from '@/components/AssignTesters.vue'

export default defineComponent({
  components: {
    BookingInfo,
    LocationInfo,
    AssignLab,
    AssignTesters
  },
  props: {
    booking: {
      type: Object,
      required: true
    },
    patient: {
      type: Object,
      required: true
    },
    projects: {
      type: Array,
      required: true
    }
  },
  setup(props) {
    const patientBooking = computed(() => {
      const data = { ...props.booking }
      data.datetime = props.patient.datetime
        ? props.patient.datetime
        : data.datetime
      return data
    })

    return { patientBooking }
  }
})
