<template>
  <DataTable
    :value="data"
    dataKey="id"
    :filters="filters"
    removableSort
    sortField="template.name"
    :sortOrder="1"
    class="p-datatable-responsive p-input-filled"
  >
    <template #empty> No emails found. </template>
    <template #loading> Loading... Please wait. </template>
    <Column :sortable="true" field="template.name" header="Type">
      <template #body="slotProps">
        <span class="p-column-title">Type</span>
        <span>{{ slotProps.data.template.name }}</span>
      </template>
    </Column>
    <Column header="To">
      <template #body="slotProps">
        <span class="p-column-title">To</span>
        <ul
          v-if="Array.isArray(slotProps.data.to || slotProps.data.bcc)"
          class="ul-emails p-m-0 p-p-0"
        >
          <li
            v-for="email in slotProps.data.to || slotProps.data.bcc"
            :key="email"
          >
            {{ email }}
          </li>
        </ul>
        <span v-else>{{ slotProps.data.to || slotProps.data.bcc }}</span>
      </template>
    </Column>
    <Column header="Date">
      <template #body="slotProps">
        <span class="p-column-title">Date</span>
        <span v-if="slotProps.data.template.name === 'BOOKING_CREATE'">
          {{
            slotProps.data.delivery.endTime
              ? theDate(slotProps.data.delivery.endTime)
              : theDate(slotProps.data.template.data.created)
          }}
        </span>
        <span
          v-else-if="slotProps.data.template.name === 'BOOKING_CONFIRMATION'"
        >
          {{
            slotProps.data.delivery.endTime
              ? theDate(slotProps.data.delivery.endTime)
              : theDate(slotProps.data.template.data.confirmed.date)
          }}
        </span>
        <span
          v-else-if="
            slotProps.data.template.name === 'PATIENT_NEGATIVE' ||
            slotProps.data.template.name === 'BOOKING_CLOSE' ||
            slotProps.data.template.name === 'BOOKING_POSITIVE'
          "
        >
          {{
            slotProps.data.delivery.endTime
              ? theDate(slotProps.data.delivery.endTime)
              : theDate(slotProps.data.template.data.completed.date)
          }}
        </span>
      </template>
    </Column>
    <Column header="State">
      <template #body="slotProps">
        <span class="p-column-title">State</span>
        <span>{{
          slotProps.data.delivery ? slotProps.data.delivery.state : ''
        }}</span>
      </template>
    </Column>
  </DataTable>
</template>

<script>
import { ref } from 'vue'
import { formatDate } from '@/helpers/date'
import DataTable from 'primevue/datatable'
import Column from 'primevue/column'

export default {
  name: 'BookingsTable',
  components: { DataTable, Column },
  props: {
    data: {
      type: Array,
      default: () => []
    }
  },
  setup() {
    const filters = ref({})

    const theDate = date => formatDate({ date: date.toDate(), string: true })

    return { filters, theDate }
  }
}
</script>

<style lang="scss" scoped>
@import '@/scss/table.scss';
.ul-emails {
  list-style: none;
  display: inline-grid;
}
</style>
