<template>
  <div v-if="$route.params.type === 'tester'">
    <ReportTester
      v-for="patient in booking.testees"
      :key="patient.id"
      :booking="booking"
      :patient="patient"
      :projects="projects"
    />
  </div>
  <ReportBooking
    v-else
    :booking="booking"
    :showResults="showResults"
    :projects="projects"
    :emails="emails"
  />
</template>

<script>
import { firestore } from '@/services/firebase'
import { ref, onUnmounted } from 'vue'
import { useRoute } from 'vue-router'
import { useToast } from 'primevue/usetoast'
import ReportBooking from '@/components/ReportBooking'
import ReportTester from '@/components/ReportTester'
import { useGetProjects } from '@/composables/useGetProjects'

export default {
  components: { ReportBooking, ReportTester },
  setup() {
    const route = useRoute()
    const booking = ref({})
    const emails = ref([])
    const toast = useToast()
    const { projects, getProjects } = useGetProjects()

    toast.removeGroup('login')

    async function getBooking() {
      if (route.params.id) {
        const docRef = firestore.collection('bookings').doc(route.params.id)

        try {
          const doc = await docRef.get()

          if (doc.exists) {
            booking.value = doc.data()
            booking.value.id = doc.id
          }
        } catch (error) {
          alert(error)
        }
      }
    }

    async function getEmails() {
      if (route.params.id) {
        firestore
          .collection('mail')
          .where('template.data.id', '==', route.params.id)
          .get()
          .then(querySnapshot => {
            emails.value = []
            querySnapshot.forEach(doc => {
              const row = doc.data()
              row.id = doc.id
              emails.value.push(row)
            })
          })
          .catch(error => {
            console.error(error.message)
          })
      }
    }

    if (route.params.id) {
      getBooking()
      getProjects()
    }

    let showResults = false
    if (route.params.type === 'medical') {
      showResults = true
    }

    if (route.params.type === 'emails') {
      getEmails()
    }

    if (route.params.type === 'tester') {
      document.querySelector('#the-header').style.display = 'none'
      document.querySelector('#the-footer').style.display = 'none'
    }

    document.querySelector('#nav').style.display = 'none'
    document.querySelector('#menu').style.display = 'none'

    const toastEl = document.getElementsByClassName('p-toast')
    toastEl.forEach(element => (element.style.display = 'none'))

    onUnmounted(() => {
      document.querySelector('#nav').style.display = 'block'
      document.querySelector('#menu').style.display = 'block'
      document.querySelector('#the-header').style.display = 'block'
      document.querySelector('#the-footer').style.display = 'block'
      toastEl.forEach(element => (element.style.display = 'block'))
    })

    return { booking, projects, showResults, emails }
  }
}
</script>
