<template>
  <div class="p-grid">
    <div class="p-col-6">
      <section>
        <h2>Booking Info</h2>
        <BookingInfo :booking="booking" :projects="projects" />
      </section>
      <section>
        <h2>Collectors</h2>
        <AssignTesters :booking="booking" />
      </section>
    </div>
    <div class="p-col-6">
      <section>
        <h2>Testing Location</h2>
        <LocationInfo :location="booking.location" />
      </section>
      <section>
        <h2>Lab</h2>
        <AssignLab :booking="booking" />
      </section>
    </div>
  </div>
  <h2>Patients</h2>
  <UpdateTestees
    :booking="booking"
    :showActions="false"
    :showResults="showResults"
  />
  <h2 class="p-mt-5" v-if="emails.length">Emails</h2>
  <EmailsTable :data="emails" v-if="emails.length" />
</template>

<script lang="ts">
import { defineComponent } from 'vue'
import BookingInfo from '@/components/BookingInfo.vue'
import LocationInfo from '@/components/LocationInfo.vue'
import AssignLab from '@/components/AssignLab.vue'
import AssignTesters from '@/components/AssignTesters.vue'
import UpdateTestees from '@/components/UpdateTestees.vue'
import EmailsTable from '@/components/EmailsTable.vue'

export default defineComponent({
  components: {
    BookingInfo,
    LocationInfo,
    AssignLab,
    AssignTesters,
    UpdateTestees,
    EmailsTable
  },
  props: {
    booking: {
      type: Object,
      default: () => {
        return {}
      }
    },
    showResults: {
      type: Boolean,
      default: false
    },
    projects: {
      type: Array,
      required: true
    },
    emails: {
      type: Array,
      default: () => []
    }
  }
})
</script>

<style lang="scss" scoped>
section {
  border: 1px solid #ccc;
  padding: 0 1rem 0.5rem 1rem;
  margin-bottom: 1rem;
}
</style>
