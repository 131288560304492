<template>
  <div class="tester">
    <div class="p-grid">
      <div class="p-col-6">
        <section>
          <h2>Booking Info</h2>
          <BookingInfo :booking="patientBooking" :projects="projects" />
        </section>
        <section>
          <h2>Collectors</h2>
          <AssignTesters :booking="booking" />
        </section>
      </div>
      <div class="p-col-6">
        <section>
          <h2>Testing Location</h2>
          <LocationInfo :location="booking.location" />
        </section>
        <section>
          <h2>Lab</h2>
          <AssignLab :booking="booking" />
        </section>
      </div>
    </div>
    <h2>Patient</h2>
    <ul class="patient">
      <li><b>First Name</b>: {{ patient.firstName }}</li>
      <li><b>Last Name</b>: {{ patient.lastName }}</li>
      <li><b>DOB</b>: {{ patient.dob }}</li>
      <li><b>Email</b>: {{ patient.email }}</li>
      <li><b>Mobile</b>: {{ patient.mobile }}</li>
      <li v-if="patient.comments"><b>Comments</b>: {{ patient.comments }}</li>
      <li><b>ID</b>: {{ patient.id }}</li>
    </ul>
  </div>
</template>

<script lang="ts">
import { defineComponent, computed } from 'vue'
import BookingInfo from '@/components/BookingInfo.vue'
import LocationInfo from '@/components/LocationInfo.vue'
import AssignLab from '@/components/AssignLab.vue'
import AssignTesters from '@/components/AssignTesters.vue'

export default defineComponent({
  components: {
    BookingInfo,
    LocationInfo,
    AssignLab,
    AssignTesters
  },
  props: {
    booking: {
      type: Object,
      required: true
    },
    patient: {
      type: Object,
      required: true
    },
    projects: {
      type: Array,
      required: true
    }
  },
  setup(props) {
    const patientBooking = computed(() => {
      const data = { ...props.booking }
      data.datetime = props.patient.datetime
        ? props.patient.datetime
        : data.datetime
      return data
    })

    return { patientBooking }
  }
})
</script>

<style lang="scss" scoped>
section {
  border: 1px solid #ccc;
  padding: 0 1rem;
  margin-bottom: 1rem;
  padding-bottom: 0.5rem;
}
.tester {
  page-break-after: always;
  margin-top: 2.5rem;
  border-bottom: 1px solid #ccc;
  padding-bottom: 0.5rem;
  .patient {
    padding-left: 1.5rem;
  }
}
.tester:first-child {
  margin-top: 0;
}
</style>
