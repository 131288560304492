
import { defineComponent } from 'vue'
import BookingInfo from '@/components/BookingInfo.vue'
import LocationInfo from '@/components/LocationInfo.vue'
import AssignLab from '@/components/AssignLab.vue'
import AssignTesters from '@/components/AssignTesters.vue'
import UpdateTestees from '@/components/UpdateTestees.vue'
import EmailsTable from '@/components/EmailsTable.vue'

export default defineComponent({
  components: {
    BookingInfo,
    LocationInfo,
    AssignLab,
    AssignTesters,
    UpdateTestees,
    EmailsTable
  },
  props: {
    booking: {
      type: Object,
      default: () => {
        return {}
      }
    },
    showResults: {
      type: Boolean,
      default: false
    },
    projects: {
      type: Array,
      required: true
    },
    emails: {
      type: Array,
      default: () => []
    }
  }
})
